* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.navbar {
    background-color: #f8f9fa;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
}

.navbar-brand {
    display: flex;
    align-items: center;
}

.navbar-brand img {
    height: 40px;
    margin-right: 10px;
}

.navbar-brand p {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 10px;
}

.navbar-nav {
    display: grid;
    grid-template-columns: repeat(6, auto);
    gap: 20px;
}

.navbar-nav li {
    list-style: none;
}

.navbar-nav li a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
}

.navbar-nav li a:hover {
    color: #FF5722;
}

.navbar-toggler {
    display: none;
}

.d {
    background-color: #222831;
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-top: 90px;
    width: 100%;
    box-sizing: border-box;
}

.d h1 {
    color: white;
    font-size: 3.5rem;
    text-align: left;
    margin: 50px 0px;
}

.d h1+p {
    color: rgb(255, 255, 255);
    text-align: left;
    max-width: 480px;
    font-size: 1.15rem;
}

.e {
    background-color: #FF5722;
    border: #FF5722;
    color: white;
    border-radius: 10px;
    padding: 10px 25px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
    display: block;
}


.f {
    width: 190px;
    height: 370px;
    border-radius: 260px;
    object-fit: cover;
    margin: 45px 20px;
    transition: transform 0.3s ease-in-out;

}

.e:hover {
    background-color: #ffffff;
    color: #FF5722;
    transform: translateX(3px);
}

.img-container {
    margin-top: 158px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.f:hover {
    transform: translateY(-5px);
}

.img-container img:nth-child(2) {
    margin-top: -50px;
}

.img-container img:nth-child(3) {
    margin-top: -100px;
}

/* Group */
.g {
    margin: 100px 0px;
}

h4 {
    margin-top: 20px;
}

/* About US */
.about {
    background: #222831;
    display: flex;
}

.about .fi {
    margin-left: 140px;
    margin-top: 70px;
    max-width: 400px;
}

.about h1 {
    color: white;
    font-size: 3.0rem;
    text-align: left;
}

.about ul {
    margin-top: 40px;
}

.about ul li {
    color: white;
    font-size: 1.3rem;
    margin-top: 10px;
    text-align: start;
}

.fi p {
    font-size: 1.3rem;
    color: white;
    margin-top: 20px;
    line-height: 1.5;
    max-width: 350px;
}

.s {
    margin-left: 100px;
    margin-top: 70px;
}

.sq {
    margin-top: 240px;
    height: 175px;
    width: 250px;
    background-color: #3e4a5b;
    border-radius: 30px;
    margin-right: 20px;
}

.sq:hover {
    transform: translateY(-5px);
}

.s .sq:nth-child(2) {
    margin-top: 180px;
}

.s .sq:nth-child(3) {
    margin-top: 100px;
}

.sq h1 {
    font-size: 2.7rem;
    color: #ffffff;
    margin: 15px 33px;
}

.sq h5 {
    color: #ffffff;
    font-size: 1.40rem;
    padding-top: 22px;
    text-align: left;
    margin-left: 30px;

}

/* Application */
.ap h1 {
    margin-left: -80px;
    margin-top: 100px;
    margin-bottom: 60px;
}

.boxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* margin: 10px 50px; */
}

.box {
    height: 60px;
    width: 48%;
    margin: 12px;
    border-bottom: 1px solid black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.box h4 {
    padding: 13px;
    margin: 0;
}

.box:hover {
    transform: translateY(-5px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
    color: #FF5722;
}

/* Service */
.service {
    background-color: rgb(245, 245, 245);
    display: flex;
    align-items: flex-start;
    padding: 20px;
}

.ser h1 {
    margin-left: 135px;
    margin-top: 395px;
    text-align: left;
    margin-right: 100px;
}

.p0 {
    margin-left: 100px;
    text-align: center;
    display: flex;
    margin-top: 80px;

}

.pl,
.p2,
.p3 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 20px;
    margin-right: 5px;

}

.p2 {
    margin-top: 110px;
}

.p3 {
    margin-top: 220px;
}

.place {
    height: 90px;
    width: 200px;
    border-radius: 10px;
    background-color: #222831;
    color: #ffffff;
    margin-top: 20px;
    padding-top: 10px;
    transition: transform 0.3s ease-in-out
}

.place:hover {
    transform: translateY(-5px);
}

/* Products */
.pro {
    margin-top: 80px;
    background-color: #222831;
}

.pro h1 {
    padding-top: 80px;
    padding-bottom: 60px;
    color: #ffffff;
}

.card-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 30px;
}

.custom-card {
    background-color: transparent;
    border: none;
    width: 25rem;
}

.custom-card img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.4s ease-in-out;
}

.card-img-top {
    border-radius: 10px;
}

.card-body {
    background-color: transparent;
    color: #ffffff;
    text-align: left;
    margin-bottom: 70px;
}

.custom-card img:hover {
    transform: rotate(2.0deg);
}

.card-text {
    margin-top: 20px;
}

.card-body .btn-primary {
    display: block;
    width: 130px;
    height: 40px;
    margin: 0 auto;
    text-align: center;
    margin-top: 30px;
    background-color: #ffffff;
    color: #000;
    border: none;
    transition: background-color 0.3s ease-in-out;
}

.card-body .btn-primary:hover {
    background-color: #FF5722;
    transform: scale(1.08);
    color: #ffffff;
}

.gallery {
    background-color: #e7e7e7;

}

.gallery h1 {
    margin: 55px 0px;
    color: #000;
    font-size: 3.5rem;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 0px 15px;
    transition: transform 0.8s ease-in-out;
}

.gallery-grid img {
    width: 100%;
    max-width: 700px;
    height: 450px;
    object-fit: cover;
    margin-bottom: 60px;
    border-radius: 10px;
}

.gallery-grid img:hover {
    transform: scale(1.03);
}

/* Pro Range */

.pro1 {
    background-color: #222831;
    color: #ffffff;
}

.pro1 h1 {
    padding-top: 80px;
    padding-bottom: 60px;
}

/* Touch */

.touch {
    background-color: #222831;
    color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

hr {
    border: none;
    height: 2px;
    background-color: #FF5722;
    margin: 0;
    opacity: 100%;
}

.touch h1 {
    padding-top: 100px;
    font-size: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.touch .mail {
    height: 80px;
    width: 500px;
    background-color: #FF5722;
    border-radius: 50px;
    text-align: center;
    line-height: 70px;
    color: #fff;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 100px;
    transition: transform 0.5s ease-in-out;
}

.touch .mail:hover {
    background-color: #3e4a5b;
    transform: translateY(-10px) scale(1.05)
}

iframe {
    margin-bottom: 50px;
    width: 750px;
    height: 400px;
}

.con-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;
    max-width: 50%;
    padding: 20px;
    box-sizing: border-box;
}

.con {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    padding: 10px;
}

.off {
    display: flex;
    align-items: center;
    gap: 0px;
    width: 100%;
}

.icon {
    font-size: 24px;
    color: #FF5722;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.text h4,
.text p {
    margin: 0;
    text-align: left;
    line-height: 1.5;
}

.text a {
    display: inline;
    margin-right: 10px;
    white-space: nowrap;
    color: inherit;
    text-decoration: none;
}

.text a:last-child {
    margin-right: 0;
}

hr {
    width: 100%;
    background-color: rgba(255, 87, 34, 0.75);
    height: 2px;
    border: none;
    margin-top: 20px;
}


/* Profile */
.profile {
    background-color: #222831;
    margin-top: 80px;
    color: #fff;
    text-align: left;
    padding-left: 150px;
}

.profile h1 {
    padding-top: 70px;
    text-align: left;
    font-size: 3.0rem;
    padding-bottom: 30px;
}

.profile h2 {
    text-align: left;
}

.profile h4 {
    text-align: left;
}

.profile p {
    text-align: left;
}

.profile img {
    width: 88%;
    margin-top: 50px;
    border-radius: 20px;
}

.profile .details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 280px;
    padding: 30px;
    row-gap: 20px;
}

.profile hr {
    background-color: rgba(255, 87, 34, 0.75);
    height: 1px;
    width: 100%;
    margin-top: 20px;
}


.profile .details p {
    margin-top: 15px;
}

.abtus {
    margin: 0;
    display: flex;
    flex-direction: row;
}

.abtus h1 {
    margin-right: 170px;
    font-size: 3.0rem;
    margin-top: 80px;
}

.abtus div:nth-child(2) {
    margin-top: 50px;
    display: grid;
    grid-template-rows: repeat(2, auto);
    row-gap: 15px;
    max-width: 920px;
    margin-bottom: 50px;
}

.abtus p {
    font-size: 1.07rem;
    margin: 0;
    line-height: 2.0;
}

.profile .tou {
    background-color: #222831;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    text-align: center;
    padding: 20px;
}

.profile .tou h1 {
    padding-top: 100px;
    font-size: 3.5rem;
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.profile .tou .ma {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: clamp(300px, 90%, 500px);
    background-color: #FF5722;
    border-radius: 50px;
    color: #fff;
    text-align: center;
    line-height: 70px;
    margin-top: 40px;
    margin-bottom: 100px;
    transition: transform 0.5s ease-in-out;
}

.profile .tou .ma:hover {
    background-color: #3e4a5b;
    transform: translateY(-10px) scale(1.05)
}

.con-second {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 auto;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.con-second .con {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-bottom: 5px;
    padding: 10px;
}





/* Responsive */
@media (max-width: 1200px) {
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    /* Navbar */
    .navbar {
        padding: 8px 15px;
    }

    .navbar-brand p {
        font-size: 1.5rem;
    }

    .navbar-nav {
        gap: 15px;
    }

    /* Group */
    .g {
        margin: 100px 0px;
    }

    h4 {
        margin-top: 20px;
    }

    .about {
        display: flex;
        flex-direction: column;
        padding-left: 0;
    }

    .about .fi {
        margin-left: 10px;
        margin-top: 70px;
        max-width: 400px;
    }

    .s {
        order: 2;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .fi {
        order: 1;
        margin-top: 0;
        margin-bottom: 0;
    }

    .sq {
        width: 85%;
        height: 85px;
        margin: 10px 30px;
        display: flex;
        padding: 10px 2px;

    }

    .sq h1 {
        padding: 0px 0px;
    }

    .sq h5 {
        text-align: left;
        margin-left: 30px;

    }

    .about h1 {
        font-size: 2.0rem;
        text-align: left;
    }

    .about p {
        font-size: 1.0rem;

    }

    .s .sq:nth-child(2) {
        margin-top: 10px;
    }

    .s .sq:nth-child(3) {
        margin-top: 10px;
        margin-bottom: 20px;
    }



    /* Application */

    .ap h1 {
        margin-left: 0px;
        margin-top: 100px;
        margin-bottom: 60px;
    }

    .boxes {
        align-self: center;
        justify-content: center;
    }




    /* Service */
    .service {

        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }


    .ser h1 {
        margin-top: 45px;
        text-align: left;
        margin-right: 100px;
    }

    /* Touch */
    .touch h1 {
        padding-top: 100px;
        font-size: 3.5rem;
        text-align: center;
    }

    .touch .mail {
        width: 90%;
        max-width: 400px;
    }

    iframe {
        width: 100%;
        max-width: 600px;
    }

    .con {
        max-width: 100%;
        margin: 0 auto;
    }

    .icon {
        margin-right: 10px;
        font-size: 28px;
        color: #FF5722;
    }

    .text {
        padding-left: 10px;
    }

    .text h4 {
        margin: 0;
    }

    .text p {
        font-size: 1.15rem;
        margin: 0;
        padding-top: 10px;
    }

    /* Profile */
    .profile .details {
        column-gap: 150px;
    }

    .profile hr {
        width: 100%;
    }

    .profile .touch {
        align-items: center;
    }

    .profile .touch h1 {
        padding-top: 100px;
        font-size: 3.5rem;
        margin-left: -100px;
    }

    .profile .touch .mail {
        display: flex;
        max-width: 80%;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        margin-top: 30px;
        width: 500px;
        margin-left: 100px;

    }

    .profile .touch iframe {
        max-width: 80%;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        margin: 0px 30px;
        margin-bottom: 50px;

    }

    .profile .touch .con {
        max-width: 90%;
        margin: 0 auto;
        margin-top: 10px;
    }

}
@media (max-width: 992px) {

    /* Navbar */
    .navbar {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .navbar-nav {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    /* Main */
    .d h1 {
        font-size: 2.5rem;
        text-align: center;
    }

    .d h1+p {
        font-size: 1.2rem;
        text-align: center;
        max-width: 90%;
    }

    /* About Us */
    .about {
        flex-direction: column;
        align-items: center;
    }

    .about .fi {
        margin-left: 0;
        text-align: center;
    }

    .s {
        margin-left: 0;
        text-align: center;
    }

    /* Products */
    .pro h1 {
        font-size: 2.5rem;
        padding-bottom: 40px;
    }

    .custom-card {
        width: 20rem;
    }

    /* Touch */
    .touch .mail {
        width: 80%;
        margin-left: 10%;
    }

    /* Profile */
    .profile {
        padding-left: 20px;
        text-align: center;
    }

    .profile h1 {
        font-size: 2.5rem;
    }

    .profile .details {
        grid-template-columns: 1fr;
        row-gap: 15px;
    }

    .profile .touch .mail {
        width: 80%;
        margin-left: 10%;
    }

    .profile .touch iframe {
        width:100%;
    }
}



/* Responsive for screens 768px and below */
@media screen and (max-width: 768px) {
    .navbar {
        /* grid-template-columns: 1fr; */
        max-width:100%;
    }

    .navbar-brand img {
        max-height: 50px;
        margin-right: 10px;
    }

    .navbar-nav {
        /* grid-template-columns: 1fr; */

    }

    .navbar-toggler {
        display: block;
        font-size: 1.5rem;
        justify-content: right;
        align-items: flex-end;
        padding-left: 10px;
        color: #fff;
        border: 1px solid #ccc;
        z-index: 10;
    }



    .d {
        margin-top: 80px;
        padding: 10px;
        max-width: 100%;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
    }

    .d h1 {
        font-size: 1.8rem;
        margin: 20px 0;
        text-align: left;
    }

    .d h1+p {
        font-size: 1.0rem;
        text-align: left;
        max-width: 100%;

    }

    .img-container {
        margin-top: 100px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
    }

    .f {
        width: 200px;
        height: 400px;
        margin: 10px 10px;
        padding-left: 10px;
    }

    /* Group */
    .g {
        max-width: 100%;
    }


    /* About Us */

    .about {
        display: flex;
        flex-direction: column;
        padding-left: 0;
    }

    .about .fi {
        margin-left: 30px;
        margin-top: 70px;
        max-width: 400px;
    }

    .s {
        order: 2;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .fi {
        order: 1;
        margin-top: 0;
        margin-bottom: 0;
    }

    .sq {
        width: 85%;
        height: 85px;
        margin: 10px 30px;
        display: flex;
        padding: 10px 2px;

    }

    .sq h1 {
        padding: 0px 0px;
    }

    .sq h5 {
        text-align: left;
        margin-left: 30px;

    }

    .about h1 {
        font-size: 2.0rem;
        text-align: left;
    }

    .about p {
        font-size: 0.9rem;
    }


    .s .sq:nth-child(2) {
        margin-top: 10px;
    }

    .s .sq:nth-child(3) {
        margin-top: 10px;
        margin-bottom: 20px;
    }


    /* Products */

    .custom-card {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-card img {
        width: 90%;
    }


    .card-img-top {
        border-radius: 10px;
    }

    .card-body {
        background-color: transparent;
        text-align: left;
        margin-bottom: 70px;
    }

    /* Application */
    .box {
        width: 100%;
    }

    .ap h1 {
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 60px;
    }

    /* Service */
    .service {
        flex-direction: column;
        padding: 5px;
        /* text-align: left; */
    }


    .ser h1 {
        margin-top: 100px;
        max-width: 100%;
        text-align: left !important;
    }


    .p0 {
        text-align: center;
        flex-direction: row;
        margin-top: 0px;
        margin: 0 auto;

    }

    .place {
        margin-left: 3px;
        height: 90px;
        padding-top: 10px;
    }


    .p2 {
        margin-top: 10px;
    }

    .p3 {
        margin-top: 10px;
    }

    /* Touch */

    .touch h1 {
        padding-top: 100px;
        font-size: 3.0rem;
        text-align: center;

    }

    .touch .mail {
        height: 80px;
        width: 400px;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 1.7rem;
        justify-content: center;
        align-items: center;

    }

    iframe {
        width: 100%;
        height: 450px;
        margin: 50px 40px;
    }

    .con-main {
        display: flex;
        margin-top: 0px;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 85%;
    }



    .off {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }


    .text {
        padding-left: 10px;
    }

    .text h4 {
        margin: 0;
        text-align: left;
    }

    .text p {
        margin: 0;
        font-size: 1.15rem;
        padding-top: 10px;
        display: block;
        text-align: left;
        max-width: 100%;
    }

    /* products */
    .gallery-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .gallery-grid img {
        width: 95%;
    }

    /* Profile */
    .profile {
        padding-left: 30px;
    }

    .profile h1 {
        padding-top: 70px;
        font-size: 2.5rem;
    }

    .profile img {
        width: 90%;
        margin: 30px;

    }

    .profile .details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 50px;
        row-gap: 20px;
    }

    .abtus {
        margin: 0;
        flex-direction: column;
    }

    .abtus h1 {
        text-align: left;
        font-size: 3.5rem;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .abtus p {
        font-size: 1.35rem;
    }

    .profile .tou h1 {
        padding-left: 0px;
        font-size: 3.0rem;

    }

    .profile .tou .ma {
        margin-bottom: 80px;
    }

    .profile .tou iframe {
        width: 100%;
    }

    .profile .touch .con {
        font-size: 1.0rem;
    }
}

/* Responsive for 480px and below */
@media screen and (max-width: 480px) {
    .navbar {
        max-width: 100%;
    }

    .navbar-brand p {
        font-size: 1.10rem;
    }

    .navbar-toggler {
        font-size: 1.0rem;
        /* padding: 10px; */
    }

    /* Main  change*/
    .d {
        margin-top: 80px;
        padding: 0px;
        max-width: 100%;
        box-sizing: border-box;
        flex-direction: column;
        align-items: flex-start;
    }

    .d h1 {
        font-size: 1.8rem;
        margin: 20px 0;
        text-align: left;
    }

    .d h1+p {
        font-size: 0.9rem;
        text-align: left;
        max-width: 100%;

    }

    .img-container {
        margin-top: 100px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 100%;
    }

    .f {
        width: 100px;
        height: 200px;
        margin: 10px 10px;
        padding-left: 10px;
    }

    /* Group */
    .g {
        max-width: 100%;
    }


    /* About Us */


    .about {
        display: flex;
        flex-direction: column;
        padding-left: 0;
    }

    .about .fi {
        margin-left: 30px;
        margin-top: 70px;
        max-width: 400px;
    }

    .s {
        order: 2;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;
        margin-top: 0;
        margin-bottom: 0;
    }

    .fi {
        order: 1;
        margin-top: 0;
        margin-bottom: 0;
    }

    .sq {
        width: 90%;
        height: 85px;
        margin-left: 0;
        display: flex;
        padding: 10px;

    }

    .sq h1 {
        padding: 0px 0px;
    }

    .sq h5 {
        margin: 0 auto;
        padding: 10px 5px;
    }

    .about h1 {
        font-size: 2.0rem;
        text-align: left;
    }

    .about p {
        font-size: 0.9rem;
    }


    .s .sq:nth-child(2) {
        margin-top: 10px;
    }

    .s .sq:nth-child(3) {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    /* Products */

    .custom-card {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .custom-card img {
        width: 90%;
    }


    .card-img-top {
        border-radius: 10px;
    }

    .card-body {
        background-color: transparent;
        text-align: left;
        margin-bottom: 70px;
    }

    /* Application */
    .box {
        width: 100%;
    }

    .ap h1 {
        margin: 0 auto;
        margin-top: 100px;
        margin-bottom: 60px;
    }

    /* Service */
    .service {
        flex-direction: column;
        padding: 5px;
    }


    .ser h1 {
        margin-top: 100px;
        max-width: 100%;
        text-align: left !important;
    }

    .p0 {
        text-align: center;
        flex-direction: column;
        margin-top: 0px;
        margin: 0 auto;

    }

    .place {
        margin-left: 3px;
        height: 90px;
        padding-top: 10px;
    }


    .p2 {
        margin-top: 10px;
    }

    .p3 {
        margin-top: 10px;
    }

    /* Touch */

    .touch h1 {
        padding-top: 100px;
        font-size: 2.0rem;
        text-align: center;

    }

    .touch .mail {
        height: 70px;
        width: 300px;
        font-size: 1.4rem;
        margin: 0 auto;
        margin-top: 30px;
        margin-bottom: 50px;
    }

    iframe {
        margin-bottom: 50px;
        width: 280px;
        height: 300px;
    }

    .con-main {
        display: flex;
        margin-top: 0px;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .off {
        display: flex;
        align-items: center;
        margin-bottom: 0px;
    }

    .text h4 {
        margin: 0;
        text-align: left;
    }

    .text p {
        margin: 0 auto;
        font-size: 0.85rem;
        max-width: 100%;
    }
    .text a {
       font-size: 0.85rem;
    }
    hr {
        max-width:  100%;
        
        margin-top: 20px;
    }

    /* products */
    .gallery-grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .gallery-grid img {
        width: 95%;
    }

    /* Profile */
    .profile {
        padding-left: 10px;
    }

    .profile h1 {
        padding-top: 70px;
        font-size: 2.5rem;
    }

    .profile img {
        width: 90%;
        margin: 10px;

    }

    .profile .details {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 20px;
        row-gap: 20px;
    }

    .abtus {
        margin: 0;
        flex-direction: column;
    }

    .abtus h1 {
        text-align: left;
        font-size: 3.5rem;
        max-width: 100%;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .abtus p {
        font-size: 1.0rem;
        text-align: left;
        max-width: 100%;
    }

    .profile .tou h1 {
        padding-left: 0px;
        font-size: 2.0rem;

    }

    .profile .tou .ma {
        height: 70px;
        width: 300px;
        font-size: 1.4rem;
    }

    .profile .tou iframe {
        margin-bottom: 50px;
        width: 280px;
        height: 300px;
    }
   
}